import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined"
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined"
// part of disable Support
// import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined"
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined"
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined"
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined"
import { type SvgIconTypeMap, type SvgIconProps } from "@mui/material"
import { type OverridableComponent } from "@mui/material/OverridableComponent"
import { EncryptStorage } from "encrypt-storage"
import { activitySync } from "../utils/activitySync"
import NewspaperIcon from "@mui/icons-material/Newspaper"

interface Link {
  title: string
  href?: string
  onclick?: () => void
  Icon: OverridableComponent<SvgIconTypeMap<unknown, "svg">> & {
    muiName: string
  }
  iconProps?: SvgIconProps
  className?: string
}

export const homeButtons = (signOutHandler: () => void): Link[] => {
  const storedLicensing = localStorage.getItem("licensing")
  const showLicensing: boolean =
    storedLicensing != null ? JSON.parse(storedLicensing) === true : false
  const storage = new EncryptStorage("jfkejfklrjklgjerlk485859809gjtkljkgjl")
  const locations = storage.getItem("locations")
  const isLocationsEmpty = locations?.length === 0
  return [
    {
      title: "Homepage",
      href: "/",
      Icon: HomeOutlinedIcon,
      iconProps: { style: { color: "#FFFFFF" } },
    },
    ...(!isLocationsEmpty
      ? [
          {
            title: "Overview",
            href: "/overview",
            Icon: DashboardOutlinedIcon,
            iconProps: { style: { color: "#FFFFFF" } },
          },
          {
            title: "Reports",
            href: "/reports",
            Icon: AssessmentOutlinedIcon,
            iconProps: { style: { color: "#FFFFFF" } },
          },
          {
            title: "Terminals",
            href: "/terminals",
            Icon: StorageOutlinedIcon,
            iconProps: { style: { color: "#FFFFFF" } },
          },
          ...(showLicensing
            ? [
                {
                  title: "Licensing",
                  href: "/licensing",
                  Icon: CurrencyExchangeOutlinedIcon,
                  iconProps: { style: { color: "#FFFFFF" } },
                },
                {
                  title: "News",
                  href: "/news",
                  Icon: NewspaperIcon,
                  iconProps: { style: { color: "#FFFFFF" } },
                },
              ]
            : []),
        ]
      : []),
    // part of disable Support
    // {
    //   title: "Support",
    //   href: "/support",
    //   Icon: SupportAgentOutlinedIcon,
    //   iconProps: { style: { color: "#FFFFFF" } },
    // },
    {
      title: "Account Settings",
      href: "/settings",
      Icon: SettingsOutlinedIcon,
      iconProps: { style: { color: "#FFFFFF" } },
    },
    {
      title: "Sign Out",
      onclick: () => {
        activitySync.notifyLogout()
        signOutHandler()
      },
      Icon: LogoutOutlinedIcon,
      iconProps: { style: { color: "#FFFFFF" } },
    },
  ]
}
